import React, { Component } from "react";
import SnookerApi from "../SnookerApi";
import NewsItem from "./NewsItem";
import ListItemLoader from "./ListItemLoader";
import { ListGroup } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";

class NewsList extends Component {
  state = {
    currentPage: 1,
    maximumPage: 1,
    loading: true,
    news: [],
  };

  constructor() {
    super();

    this.api = new SnookerApi();
    this._loadPageAsync = this._loadPageAsync.bind(this);
  }

  render() {
    return (
      <div className="NewsList">
        <ListGroup tag="div" flush>
          {this.state.loading && this.state.currentPage === 1 && (
            <ListItemLoader />
          )}
          <InfiniteScroll
            pageStart={1}
            loadMore={this._loadPageAsync}
            hasMore={this.state.currentPage < this.state.maximumPage}
            initialLoad={false}
            loader={<ListItemLoader key={0} />}
          >
            {this.state.news.map((item, index) => (
              <NewsItem item={item} key={index.toString()} />
            ))}
          </InfiniteScroll>
        </ListGroup>
      </div>
    );
  }

  componentDidMount() {
    this._loadPageAsync(1);
  }

  _loadPageAsync = async (page) => {
    const response = await this.api.newsList(page);
    const data = await response.json();
    const totalPages = Math.ceil(
      data.pagination.total_count / data.pagination.per_page
    );

    const news = this.state.news;
    for (let i in data.records) {
      news.push(data.records[i]);
    }
    this.setState({
      news: news,
      currentPage: page,
      maximumPage: totalPages,
      loading: false,
    });
  };
}

export default NewsList;
