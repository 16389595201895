import React, { Component } from "react";
import Navbar from "./components/Navbar";
import HomeScreen from "./screens/HomeScreen";
import SettingsScreen from "./screens/SettingsScreen";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.scss";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Navbar />
          <div className="no-gutters">
            <Route path="/" exact component={HomeScreen} />
            <Route path="/settings" component={SettingsScreen} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
