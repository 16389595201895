import React, { Component } from "react";
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import moment from "moment";

class NewsItem extends Component {
  render() {
    return (
      <ListGroupItem
        className="NewsItem"
        tag="a"
        action
        href={this.props.item.article_url}
      >
        <ListGroupItemHeading>{this.props.item.title}</ListGroupItemHeading>
        <ListGroupItemText className="clearfix">
          {this.props.item.thumbnail_url && (
            <img
              src={this.props.item.thumbnail_url}
              alt={this.props.item.title}
              style={{ height: 45 }}
              className="thumbnail"
            />
          )}
          {this.props.item.lead}
        </ListGroupItemText>
        <div className="clearfix">
          <img
            src={this.props.item.source_logo_url}
            alt={this.props.item.title}
            style={{ height: 18 }}
          />{" "}
          <small>{this._relativeTime(this.props.item.published_at)}</small>
        </div>
      </ListGroupItem>
    );
  }

  _relativeTime(time) {
    return moment(time).fromNow();
  }
}

export default NewsItem;
