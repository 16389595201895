class SnookerApi {
  constructor() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      this.api_url = "http://localhost:3001";
      this.ws_url = "ws://localhost:3001/websocket";
    } else {
      this.api_url = "https://api.snooker.fan";
      this.ws_url = "wss://api.snooker.fan/websocket";
    }
    this.token = null;
  }

  signIn(googleResponse) {
    return this._post("/auth/google_sign_in.json", googleResponse);
  }

  playersList(page) {
    page = page || 1;
    return this._get("/players.json?page=" + page);
  }

  profile() {
    return this._get("/auth/profile.json");
  }

  profileUpdate(params) {
    return this._patch("/auth/profile.json", params);
  }

  newsList(page) {
    page = page || 1;
    return this._get("/news.json?page=" + page);
  }

  addSubscription(params) {
    return this._post("/web_notification_subscription.json", params);
  }

  removeSubscription(params) {
    return this._delete("/web_notification_subscription.json", params);
  }

  _get = async path => {
    let options = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    if (this.token) {
      options.headers["Authorization"] = "Token " + this.token;
    }

    return fetch(this.api_url + path, options);
  };

  _post = async (path, data) => {
    let options = {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    if (this.token) {
      options.headers["Authorization"] = "Token " + this.token;
    }

    return fetch(this.api_url + path, options);
  };

  _patch = async (path, data) => {
    let options = {
      method: "patch",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    if (this.token) {
      options.headers["Authorization"] = "Token " + this.token;
    }

    return fetch(this.api_url + path, options);
  };

  _delete = async (path, data) => {
    let options = {
      method: "delete",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    if (this.token) {
      options.headers["Authorization"] = "Token " + this.token;
    }

    return fetch(this.api_url + path, options);
  };
}

export default SnookerApi;
