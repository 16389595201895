import React, { Component } from "react";
import {
  Collapse,
  Navbar as BootstrapNavbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = {
    isOpen: false,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <BootstrapNavbar color="primary" dark fixed="top" expand="md">
        <NavbarBrand href="/">snooker.fan</NavbarBrand>
        <NavbarToggler aria-label="Menu" onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink tag={Link} onClick={this.toggle} to="/">
                News
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} onClick={this.toggle} to="/settings">
                Settings
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </BootstrapNavbar>
    );
  }
}

export default Navbar;
