import firebase from "firebase/app";
import "@firebase/messaging";

const config = {
  apiKey: "AIzaSyCotZbZPc_rY70oJaROSdApZcyke7i749w",
  authDomain: "snookerfan.firebaseapp.com",
  databaseURL: "https://snookerfan.firebaseio.com",
  projectId: "snookerfan",
  storageBucket: "snookerfan.appspot.com",
  messagingSenderId: "228762902511",
  appId: "1:228762902511:web:36b25d34cdc42c2f"
};

firebase.initializeApp(config);

let messaging;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("../firebase-messaging-sw.js")
      .then(function(registration) {
        messaging.useServiceWorker(registration);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
}

export { messaging };
