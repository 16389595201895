import React, { Component } from "react";
import NotificationSettings from "../components/NotificationSettings";
import { Col } from "reactstrap";

class SettingsScreen extends Component {
  render() {
    return (
      <Col xs={{ size: "auto", offset: 1 }}>
        <NotificationSettings />
      </Col>
    );
  }
}

export default SettingsScreen;
