import React, { Component } from "react";
import { FormGroup, CustomInput, Label } from "reactstrap";
import { messaging } from "./Firebase";
import SnookerApi from "../SnookerApi";

const INSTANCE_TOKEN = "firebaseMessagingToken";

class NotificationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { notificationsEnabled: false };
    this.api = new SnookerApi();
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.notificationPermission = this.notificationPermission.bind(this);
    this.addSubscription = this.addSubscription.bind(this);
  }

  handleCheckbox(event) {
    event.currentTarget.checked
      ? this.notificationsEnable()
      : this.notificationsDisable();
  }

  async notificationsEnable() {
    const permission = await this.notificationPermission();
    if (permission) {
      this.setState({ notificationsEnabled: true });
      console.log("enabled");
      localStorage.setItem("notificationsEnabled", "TRUE");
    }
  }

  notificationsDisable() {
    this.setState({ notificationsEnabled: false });
    console.log("disabled");
    localStorage.setItem("notificationsEnabled", "FALSE");

    if (localStorage.getItem(INSTANCE_TOKEN) !== null) {
      this.api.removeSubscription({
        topic: "news",
        token: localStorage.getItem(INSTANCE_TOKEN)
      });
      localStorage.removeItem(INSTANCE_TOKEN);
    }
  }

  async notificationPermission() {
    let permissionGranted = false;
    try {
      /* request permission if not granted */
      if (Notification.permission !== "granted") {
        console.log('Notification.permission !== "granted"');

        await messaging.requestPermission();
      }
      /* get instance token if not available */
      if (localStorage.getItem(INSTANCE_TOKEN) !== null) {
        console.log("localStorage.getItem(INSTANCE_TOKEN) !== null");

        permissionGranted = true;
      } else {
        console.log("localStorage.getItem(INSTANCE_TOKEN) === null");

        const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
        this.addSubscription(token);
        localStorage.setItem(INSTANCE_TOKEN, token);
        permissionGranted = true;
      }
    } catch (err) {
      console.log(err);
      if (
        err.hasOwnProperty("code") &&
        err.code === "messaging/permission-default"
      )
        console.log("You need to allow the site to send notifications");
      else if (
        err.hasOwnProperty("code") &&
        err.code === "messaging/permission-blocked"
      )
        console.log(
          "Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings"
        );
      else console.log("Unable to subscribe you to notifications");
    } finally {
      return permissionGranted;
    }
  }

  async addSubscription(token) {
    this.api.addSubscription({ topic: "news", token: token });
  }

  componentDidMount() {
    this.setState({
      notificationsEnabled:
        localStorage.getItem("notificationsEnabled") === "TRUE"
    });
  }

  render() {
    return (
      <div>
        <FormGroup check>
          <Label check>
            <CustomInput 
              type="switch"
              id="notificationsEnabled"
              onChange={e => this.handleCheckbox(e)}
              checked={this.state.notificationsEnabled}
              label="Notifications"
            />
          </Label>
        </FormGroup>
      </div>
    );
  }
}

export default NotificationSettings;
