import React, { Component } from "react";
import NewsList from "../components/NewsList";
import { Col } from "reactstrap";

class HomeScreen extends Component {
  render() {
    return (
      <Col md="12">
        <NewsList />
      </Col>
    );
  }
}

export default HomeScreen;
