import React, { Component } from "react";
import { ListGroupItem, Spinner } from "reactstrap";

class ListItemLoader extends Component {
  render() {
    return (
      <ListGroupItem
        tag="div"
        className="ListItemLoader d-flex justify-content-center"
      >
        <Spinner color="primary" />
      </ListGroupItem>
    );
  }
}

export default ListItemLoader;
